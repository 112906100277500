<template>
  <div class="elv-reconciliation-matched-page">
    <Header />
    <HeaderTabs />
    <div class="elv-reconciliation-matched-container">
      <div class="elv-reconciliation-matched-table-filter">
        <!-- <ListScreening /> -->
      </div>
      <div class="elv-reconciliation-matched-table-content">
        <MatchedTable
          ref="matchedTableRef"
          :match-params="matchParams"
          :table-data="matchData"
          :table-loading="matchLoading"
          :multiple-selection="multipleSelection"
          @onResetList="getReconciliationMatchList()"
          @on-change-page="onChangeTransactionsPage"
          @onChangeMultipleSelection="onChangeMultipleSelection"
          @on-change-page-size="onChangeTransactionsPageSize"
          @onUnmatchedReconciliation="onUnmatchedReconciliation"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'
import Header from '../components/Header.vue'
import HeaderTabs from '../components/HeaderTabs.vue'
import ReconciliationApi from '@/api/ReconciliationApi'
import MatchedTable from './components/MatchedTable.vue'
// import ListScreening from './components/ListScreening.vue'
import { ReconciliationMatchedType } from '#/ReconciliationTypes'

const route = useRoute()
const matchLoading = ref(false)
const matchedTableRef = ref()

const matchParams = ref({
  page: 1,
  limit: 20,
  sortField: 'datetime',
  sortDirection: 'DESC'
})

const multipleSelection: any = ref([])
const matchData = ref<ReconciliationMatchedType>({ totalCount: 0, list: [] })

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const reconciliationSetId = computed(() => {
  return String(route.params?.reconciliationSetId)
})

const getReconciliationMatchList = async () => {
  try {
    matchLoading.value = true
    const { data } = await ReconciliationApi.getReconciliationMatchList(
      entityId.value,
      reconciliationSetId.value,
      matchParams.value
    )
    matchData.value = data
    matchedTableRef.value.tableListRef?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    matchLoading.value = false
  }
}

const onChangeTransactionsPageSize = (limit: number) => {
  matchParams.value.limit = limit
  matchParams.value.page = 1
  getReconciliationMatchList()
}

const onChangeTransactionsPage = (page: number) => {
  matchParams.value.page = page
  getReconciliationMatchList()
}

const onChangeMultipleSelection = (val: any) => {
  multipleSelection.value = val
}

const onUnmatchedReconciliation = async (params: any, batch: boolean = false) => {
  try {
    matchLoading.value = true
    await ReconciliationApi.batchReconciliationUnmatched(entityId.value, reconciliationSetId.value, {
      reconciliationMatchIds: params
    })
    ElMessage.success('Unmatched successfully')
    if (batch) {
      multipleSelection.value = []
      matchedTableRef.value.tableListRef?.clearSelection()
    }
    getReconciliationMatchList()
  } catch (error: any) {
    ElMessage.error(error.message)
    matchLoading.value = false
  }
}

watch(
  () => route,
  async () => {
    if (route.name === 'entity-reconciliation-matched' && reconciliationSetId.value && entityId.value) {
      getReconciliationMatchList()
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  multipleSelection.value = []
})
</script>

<style lang="scss" scoped>
.elv-reconciliation-matched-page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.elv-reconciliation-matched-container {
  flex: 1;
  min-height: 0;
  position: relative;

  .elv-reconciliation-matched-table-content {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 4px;
    box-sizing: border-box;
  }
  // .elv-reconciliation-matched-table-filter {
  //   min-height: 39px;
  // }
}
</style>
