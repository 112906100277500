<template>
  <div class="elv-reconciliation-matched-table-wrapper">
    <el-table
      ref="tableListRef"
      v-loading="props.tableLoading"
      :data="props.tableData?.list"
      border
      width="100%"
      stripe
      row-key="reconciliationMatchId"
      class="elv-reconciliation-matched-table"
      header-cell-class-name="elv-reconciliation-matched-table-header__cell"
      header-row-class-name="elv-reconciliation-matched-table-header"
      cell-class-name="elv-reconciliation-matched-table-row__cell"
      @row-click="onCellClicked"
      @selection-change="onSelectionChange"
    >
      <el-table-column type="selection" width="40" :disabled="true" fixed="left" reserve-selection />
      <el-table-column width="150" :label="t('report.matchId')" fixed="left">
        <template #default="{ row }">
          <div class="elv-reconciliation-matched-table-row__cell-item">
            <TextCell :text="row.matchNo" align="left" font-family="Plus Jakarta Sans" />
          </div>
        </template>
      </el-table-column>
      <el-table-column width="195" :label="t('report.matchedBy')" fixed="left">
        <template #default="{ row }">
          <div class="elv-reconciliation-matched-table-row__cell-item">
            <VerticalTextCell
              :up-text="row?.user?.name ? `Manual Match: ${row?.user?.name}` : `Rule: ${row.reconciliationRule?.name}`"
              up-font-family="Plus Jakarta Sans"
              :down-text="
                dayjs(row.datetime)
                  .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                  .format('YYYY/MM/DD HH:mm')
              "
              align="left"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column class-name="divider" width="6" />
      <el-table-column :label="t('report.Transactions')" header-align="center">
        <el-table-column width="150" :label="t('common.dateTime')" label-class-name="is-group-header">
          <template #default="{ row }">
            <div
              v-for="(item, index) in row.transactionList"
              :key="index"
              :style="{
                height: trxCellHeight(row).value
              }"
              class="elv-reconciliation-matched-table-row__cell-item"
            >
              <DateTimeCell :params="{ data: item }" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.Currency')" width="110" label-class-name="is-group-header">
          <template #default="{ row }">
            <div
              v-for="(item, index) in row.transactionList"
              :key="index"
              :style="{
                height: trxCellHeight(row).value
              }"
              class="elv-reconciliation-matched-table-row__cell-item"
            >
              <CurrencyCell :params="{ data: item, value: { cellName: 'Currency' } }" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="t('report.Amount')"
          width="120"
          label-class-name="elv-reconciliation-matched-table-row__amount is-group-header"
          align="right"
          header-align="right"
        >
          <template #default="{ row }">
            <div
              v-for="(item, index) in row.transactionList"
              :key="index"
              :style="{
                height: trxCellHeight(row).value
              }"
              class="elv-reconciliation-matched-table-row__cell-item"
            >
              <TextCell
                :text="formatNumberToSignificantDigits(item.amount ?? 0, 2, '', false)"
                align="right"
                :weight="500"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.From/To')" min-width="314" label-class-name="is-group-header">
          <template #default="{ row }">
            <div
              v-for="(item, index) in row.transactionList"
              :key="index"
              :style="{
                height: trxCellHeight(row).value
              }"
              class="elv-reconciliation-matched-table-row__cell-item"
            >
              <FromToCell :params="{ data: item }" />
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column class-name="divider" width="6" />
      <el-table-column :label="t('common.ledger')" header-align="center">
        <el-table-column width="90" :label="t('common.date')" label-class-name="is-group-header">
          <template #default="{ row }">
            <div
              v-for="(item, index) in row.ledgerList"
              :key="index"
              :style="{
                height: ledgerCellHeight(row).value
              }"
              class="elv-reconciliation-matched-table-row__cell-item"
            >
              <VerticalTextCell
                :up-text="
                  dayjs(item.datetime)
                    .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                    .format('YYYY/MM/DD')
                "
                :down-text="item?.journal?.journalNo ?? ''"
                align="left"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="t('report.journalType')" label-class-name="is-group-header">
          <template #default="{ row }">
            <div
              v-for="(item, index) in row.ledgerList"
              :key="index"
              :style="{
                height: ledgerCellHeight(row).value
              }"
              class="elv-reconciliation-matched-table-row__cell-item"
            >
              <TextCell :text="item?.journal?.journalType?.name ?? ''" align="left" font-family="Plus Jakarta Sans" />
            </div>
          </template>
        </el-table-column>
        <el-table-column width="60" :label="t('report.Dr/Cr')" label-class-name="is-group-header" align="center">
          <template #default="{ row }">
            <div
              v-for="(item, index) in row.ledgerList"
              :key="index"
              :style="{
                height: ledgerCellHeight(row).value
              }"
              class="elv-reconciliation-matched-table-row__cell-item"
            >
              <TextCell
                :text="item.credit !== null && item.credit !== '0' ? 'Cr' : 'Dr'"
                align="center"
                font-family="Plus Jakarta Sans"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column width="150" :label="t('report.Account')" label-class-name="is-group-header">
          <template #default="{ row }">
            <div
              v-for="(item, index) in row.ledgerList"
              :key="index"
              :style="{
                height: ledgerCellHeight(row).value
              }"
              class="elv-reconciliation-matched-table-row__cell-item"
            >
              <TextCell :text="item.chartOfAccount?.name" align="left" font-family="Plus Jakarta Sans" />
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" :label="t('report.auxiliaryCode')" label-class-name="is-group-header">
          <template #default="{ row }">
            <div
              v-for="(item, index) in row.ledgerList"
              :key="index"
              :style="{
                height: ledgerCellHeight(row).value
              }"
              class="elv-reconciliation-matched-table-row__cell-item"
            >
              <AuxiliaryCodeCell :params="{ data: item }" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="90"
          :label="t('report.amount')"
          header-align="right"
          align="right"
          label-class-name="is-group-header"
        >
          <template #default="{ row }">
            <div
              v-for="(item, index) in row.ledgerList"
              :key="index"
              :style="{
                height: ledgerCellHeight(row).value
              }"
              class="elv-reconciliation-matched-table-row__cell-item"
            >
              <TextCell
                :text="
                  formatNumberToSignificantDigits(
                    item.credit !== null && item.credit !== '0' && Number(item.credit) !== 0 ? item.credit : item.debit,
                    2,
                    '',
                    false
                  )
                "
                align="right"
                :weight="500"
              />
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column class-name="divider" width="6" />
      <el-table-column width="40" fixed="right">
        <template #default="{ row }">
          <div class="elv-reconciliation-matched-table__operating">
            <Popover
              trigger="focus"
              placement="bottomLeft"
              overlay-class-name="elv-reconciliation-matched-table__operating-popover"
              :offset="2"
            >
              <SvgIcon
                width="14"
                height="14"
                name="more-dian"
                class="elv-reconciliation-matched-table__operating-icon"
              />
              <template #content>
                <div class="elv-reconciliation-matched-table__operating-item" @click="onOpenMatchDetail(row)">
                  {{ t('report.matchDetail') }}
                </div>
                <div class="elv-reconciliation-matched-table__operating-item" @click="onUnmatchedReconciliation(row)">
                  {{ t('report.unmatch') }}
                </div>
              </template>
            </Popover>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <elv-pagination
      class="elv-transactions-list-pagination"
      size-popper-class="elv-transactions-pagination__sizes-popper"
      :limit="props.matchParams.limit"
      :current-page="props.matchParams.page"
      :total="props.tableData.totalCount"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />

    <MatchDetailDialog
      ref="matchDetailDialogRef"
      :model="matchDetailDialogType"
      :reconciliation-match-id="overlayDrawerData.currentData.reconciliationMatchId ?? ''"
      :current-data="overlayDrawerData.currentData"
      @onResetList="emit('onResetList')"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Toolbar from './Toolbar.vue'
import { isNumber } from 'lodash-es'
import { ElMessage } from 'element-plus'
import timezone from 'dayjs/plugin/timezone'
import { notification, Popover } from 'ant-design-vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import TextCell from '@/components/Base/Table/TextCell.vue'
import { formatNumberToSignificantDigits } from '@/lib/utils'
import FromToCell from '@/components/Reports/Cell/FromToCell.vue'
import DateTimeCell from '@/components/Reports/Cell/DateTimeCell.vue'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'
import VerticalTextCell from '@/components/Base/Table/VerticalTextCell.vue'
import AuxiliaryCodeCell from '../../components/Cell/AuxiliaryCodeCell.vue'
import MatchDetailDialog from '@/pages/Financials/Project/components/MatchDetailDialog.vue'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  matchParams: {
    type: Object,
    required: true
  },
  multipleSelection: {
    type: Array,
    required: true
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const entityStore = useEntityStore()
const emit = defineEmits([
  'onResetList',
  'onChangePage',
  'onChangePageSize',
  'onChangeMultipleSelection',
  'onUnmatchedReconciliation'
])

const tableListRef = ref()
const matchDetailDialogRef = ref()
const matchDetailDialogType = ref('MATCHED')
const overlayDrawerData: any = reactive({
  type: '',
  isBatch: false,
  currentData: {}
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const ledgerCellHeight: any = useComputedHook((row: any) => {
  return `${
    row.transactionList.length > row.ledgerList.length ? (row.transactionList.length * 42) / row.ledgerList.length : 42
  }px`
})

const trxCellHeight: any = useComputedHook((row: any) => {
  return `${
    row.ledgerList.length > row.transactionList.length ? (row.ledgerList.length * 42) / row.transactionList.length : 42
  }px`
})

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any, column: any, e: any) => {
  try {
    console.log(row)
    overlayDrawerData.currentData = row
    if (
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__copy' &&
      e.target?.className !== 'elv-reconciliation-matched-table__operating' &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__txHash' &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom' &&
      !isNumber(e.target?.className?.animVal?.indexOf('elv-reconciliation-matched-table__operating-icon')) &&
      e.target?.className !== 'elv-reconciliation-matched-table__operating-icon' &&
      e.target?.href?.animVal !== '#icon-more-dian'
    ) {
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.transaction?.viewDetail
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      matchDetailDialogRef.value?.onCheckMatchDetailDialog()
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

const onBatchDeleteReconciliationMatch = () => {
  emit(
    'onUnmatchedReconciliation',
    props.multipleSelection.map((item: any) => item.reconciliationMatchId),
    true
  )
  notification.close('elv-reconciliation-matched-table-toolbar')
}

const onClearSelected = () => {
  notification.close('elv-reconciliation-matched-table-toolbar')
  tableListRef.value?.clearSelection()
}

const onOpenMatchDetail = (row: any) => {
  overlayDrawerData.currentData = row
  matchDetailDialogRef.value?.onCheckMatchDetailDialog()
}

const onUnmatchedReconciliation = (row: any) => {
  emit('onUnmatchedReconciliation', [row.reconciliationMatchId])
}

const onSelectionChange = (val: any[]) => {
  emit('onChangeMultipleSelection', val)
  overlayDrawerData.isBatch = true
  if (val.length) {
    notification.open({
      key: 'elv-reconciliation-matched-table-toolbar',
      message: '',
      description: () =>
        h(Toolbar, {
          selectedTotal: val?.length,
          multipleSelection: props.multipleSelection,
          onClearSelected,
          onBatchDeleteReconciliationMatch
        }),
      class: 'elv-reconciliation-matched-table-toolbar elv-table-toolbar',
      duration: 0,
      placement: 'bottom'
    })
  } else {
    notification.close('elv-reconciliation-matched-table-toolbar')
  }
}

defineExpose({ tableListRef })
</script>

<style lang="scss">
.elv-reconciliation-matched-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  // width: 100%;
  height: calc(100% - 54px);
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.is-scrolling-middle,
  &.is-scrolling-right,
  &.is-scrolling-left {
    .elv-reconciliation-matched-table-header
      .elv-reconciliation-matched-table-header__cell.el-table-fixed-column--right {
      border-left: 0px !important;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .el-table__row {
    &.elv-table__row--striped {
      background: #f9fafb !important;

      td {
        background: #f9fafb !important;
      }
    }

    &.is-hover-row td {
      background: #f5f7fa !important;
    }

    &:hover td {
      background: transparent;
    }
  }

  .elv-reconciliation-matched-table-header {
    background: #eef4fb;

    .elv-reconciliation-matched-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &.el-table-fixed-column--left {
        background: #eef4fb !important;
      }

      &.el-table-fixed-column--right.is-first-column {
        background: #eef4fb !important;
      }

      &:last-child {
        border-right: 0px;
      }

      &.is-group-header {
        height: 29px;
      }

      &.is-center {
        height: 20px;

        .cell {
          justify-content: center;
        }
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
        white-space: nowrap;
      }
    }
  }

  .elv-reconciliation-matched-table-row__cell {
    padding: 0;
    min-height: 42px;
    cursor: pointer;

    &:last-child {
      border-right: 0px;
    }

    &.is-center {
      .elv-table-text {
        align-items: center;
        justify-content: space-around;
      }
    }

    .cell {
      padding: 0;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .elv-table-text {
        .normal {
          white-space: nowrap;
        }
      }
    }

    .elv-reconciliation-matched-table-row__cell-item {
      display: flex;
      align-items: center;
      padding: 0 10px;
      min-height: 42px;
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid #edf0f3;

      .elv-base-cell-text {
        white-space: nowrap;
      }

      &:last-child {
        border-bottom: 0px;
      }
    }

    &.is-right .cell {
      text-align: right;

      .elv-reconciliation-matched-table-row__cell-item {
        justify-content: flex-end;
      }
    }

    &.is-center .cell {
      text-align: center;

      .elv-reconciliation-matched-table-row__cell-item {
        justify-content: center;
      }
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }

    .elv-reconciliation-matched-table__operating {
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      .elv-reconciliation-matched-table__operating-icon {
        fill: #838d95;
        cursor: pointer;
      }

      .el-tooltip__trigger {
        color: #2f63eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        cursor: pointer;
        align-items: center;
      }
    }
  }

  .el-table__footer-wrapper {
    height: 42px;

    .el-table__footer {
      height: 100%;

      .cell.elv-reconciliation-matched-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-reconciliation-matched-table-row__amount {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;

        p {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: none;
        }
      }
    }
  }

  .is-group .el-table__cell {
    background: none !important;
  }

  .is-group .is-center {
    justify-content: center;
  }

  .is-group .elv-reconciliation-matched-table-header__cell {
    &:last-child {
      border-right: 1px solid #e4e7eb !important;
    }
  }

  .el-table__header {
    .divider {
      border: none;
      border-left: 1px solid #ced7e0;
      border-right: 1px solid #ced7e0;
      background: #eef4fb !important;
      border-bottom: none;
    }

    .divider + th {
      border-left: 1px solid #e4e7eb;
    }
  }

  .el-table__body {
    td.divider {
      border: none;
      border-left: 1px solid #ced7e0;
      border-right: 1px solid #ced7e0;
      background: #eef4fb !important;
    }

    .divider + th {
      border-left: 1px solid #e4e7eb;
    }
  }
}

.elv-transactions-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}

.ant-popover.elv-reconciliation-matched-table__operating-popover {
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  background: #fff;
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 0px rgba(0, 0, 0, 0.05);

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 0px;
    box-shadow: none;
    width: 161px;
    border-radius: 6px;
  }

  .elv-reconciliation-matched-table__operating-item {
    display: flex;
    height: 32px;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
    align-items: center;
    align-self: stretch;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: background 0.1s;

    svg {
      margin-right: 8px;
    }

    &:hover {
      background: #f9fafb;
    }
  }
}
</style>
<style lang="scss" scoped>
.elv-reconciliation-matched-table-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
